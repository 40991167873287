<template>
  <article id="wrap">
    <form>
      <AppHeader theme="yellow">パスワードを忘れた方</AppHeader>
      <section class="contents">
        <div class="contentInner">
          <div class="white_bg_area2">
            <div class="mb_20">
              ご登録時のメールアドレスを入力して「送信」ボタンをタップしてください。<br>※新しいパスワードが発行されますので、お忘れになったパスワードはご利用できなくなります。
            </div>
            <div :class="{ 'form-group--error': $v.password.$error }">
              <label for="pw1">新しいパスワード</label>
              <div class="error" v-if="$v.password.$dirty && !$v.password.required">入力してください</div>
              <div class="error" v-if="$v.password.$dirty && !$v.password.minLength">4文字以上で入力してください</div>
              <div class="error" v-if="$v.password.$dirty && !$v.password.maxLength">200文字以内で入力してください</div>
              <input type="password" id="pw1" placeholder="パスワードを入力してください" v-model="password">
            </div>
            <div :class="{ 'form-group--error': $v.password_confirm.$error }">
              <label for="pw2">新しいパスワード確認</label>
              <div class="error" v-if="$v.password_confirm.$dirty && !$v.password_confirm.sameAsPassword">パスワードと同じ文字列を入力してください</div>
              <input type="password" id="pw2" placeholder="パスワードを入力してください" v-model="password_confirm">
            </div>
            <div class="bt_form"><button type="button" value="send" @click="send">パスワードを再設定</button></div>
          </div><!-- .white_bg_area -->
        </div><!--.contentInner-->
      </section><!--.contents-->
      <AppFooter theme="yellow" fixed></AppFooter>
    </form>
  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import {validationMixin} from 'vuelidate';
import {maxLength, required, minLength, sameAs} from 'vuelidate/lib/validators';
export default {
  name: 'PasswordInput',
  components: {AppHeader, AppFooter},
  data () {
    return {
      password: '',
      password_confirm: '',
      token: '',
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      password: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(200),
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      try {
        const user_id = this.$route.params.user_id;
        const remember_token = this.$route.params.remember_token;
        const response = await this.$http.put('authorize', {
          user_id, remember_token
        });
        this.token = response.data;
      } catch (e) {
        this.$router.push({name:'home'});
      }
    },
    async send () {
      this.$v.$touch();
      if (!this.$v.$error) {
        await this.$http.put('me', {
          password: this.password
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          }
        });
        this.$router.push({name:'login'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#wrap {
  .form-group--error {
    input, select, textarea {
      border: 1px solid #ffd3ce;
      background-color: #ffd3ce;
    }
  }
  .error {
    font-size: 10rem;
    color: #ff7666;
    margin: 5px 0 -5px;
  }
}
</style>